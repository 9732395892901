@import "former-kit-skin-pagarme/dist/styles/colors/light.css";
@import "former-kit-skin-pagarme/dist/styles/typography.css";
@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.modal div[class*="index_modal"] {
  position: relative;
}

.container {
  display: grid;
  grid-template-rows: auto;
  row-gap: 40px;
  align-items: center;
  justify-content: center;
  padding: 32px;
  font-family: var(--body-font-family, sans-serif);
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: var(--color-birdperson-gray-600, #30313c);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.title {
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  margin: 0;
  text-align: left;
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: center;
}

.closeButtonContainer {
  display: flex;
  flex-direction: row-reverse;
}

.closeButton {
  all: unset;
  position: absolute;
  cursor: pointer;
  right: 0;
  margin: var(--spacing-tiny);
}

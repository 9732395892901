@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.pageWrapper {
  width: 100%;
  max-width: 540px;
}

.updateInformation {
  display: flex;
  align-items: center;
  gap: var(--spacing-tiny, 8px);
}

.checkUpdateButton {
  margin-top: var(--spacing-medium, 24px);
}

.checkUpdateButton button {
  width: 100%;
}

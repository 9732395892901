@import "former-kit-skin-pagarme/dist/styles/colors/light.css";
@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.emptyState {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;

  & > div:last-of-type {
    margin-left: 60px;
    max-width: 480px;
  }

  & span {
    font-weight: bold;
    margin: auto 4px;
  }
}

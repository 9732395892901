@import "former-kit-skin-pagarme/dist/styles/colors/light.css";
@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  background-color: var(--color-squanchy-gray-20, #f2f2f3);
  min-height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
  padding: 0 24px 24px 24px;
}

.alertOldKeys {
  border-radius: 8px;
  border: 1px solid #f6dac5;
  background-color: #f5eae2;
  padding: 16px;
  margin: 0;
  color: #c7723b;
}

.icon {
  color: #c7723b;
  padding-right: 4px;
}

@import "former-kit-skin-pagarme/dist/styles/typography.css";
@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.testEnviromentAlert > [class*="index_alert"] {
  background-color: #fef3cd;
  border-color: #816404;
  color: #816404;
  padding: var(--spacing-small, 16px) var(--spacing-medium, 24px);
  font-family: var(--body-font-family, sans-serif);
  font-weight: normal;
  font-size: 14px;
}

.testEnviromentAlert > [class*="index_alert"] a {
  color: #816404;
  text-decoration: underline;
}

.testEnviromentAlert [class*="index_icon"] {
  padding-right: var(--spacing-large, 32px);
}

@import "former-kit-skin-pagarme/dist/styles/colors/light.css";

.card {
  border-radius: 8px;
  box-shadow: 3px 3px 15px rgba(var(--color-birdperson-gray-800), 0.15);
}

.clickableCard {
  cursor: pointer;
}

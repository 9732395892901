@import "former-kit-skin-pagarme/dist/styles/spacing.css";
@import "former-kit-skin-pagarme/dist/styles/colors.css";

.cardOptionsContainer > div {
  margin-bottom: var(--spacing-large);
}

.content {
  align-items: center;
  display: flex;
  padding: var(--spacing-medium);
}

.contentWithoutSub {
  padding: var(--spacing-small) var(--spacing-medium);
}

.contentWithoutSubAndIcon {
  padding: var(--spacing-small);
}

.cardIcon {
  margin-right: var(--spacing-medium);
}

.cardIcon:empty {
  display: none;
}

.cardText {
  color: var(--color-birdperson-gray-600);

  & p {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }

  & span {
    color: var(--color-squanchy-gray-300);
    font-size: 12px;
    line-height: 14px;
  }
}

@media (max-width: 425px) {

  .content {
    padding: var(--spacing-small);
  }

  .cardText {

    & p {
      font-size: 14px;
    }
  }
}

@import "former-kit-skin-pagarme/dist/styles/colors/light.css";
@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.overlay {
  align-items: flex-start;
  background-color: rgba(var(--color-white), 0.95);
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 5;
}

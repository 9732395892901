@import "former-kit-skin-pagarme/dist/styles/colors/light.css";

.pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  background-color: var(--color-squanchy-gray-20, #f2f2f3);
  min-height: 100vh;
}

.pageForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 540px;
  min-width: 540px;
}

.pageWrapper {
  width: 100%;
  max-width: 540px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.pageWrapper > button {
  width: 100%;
}

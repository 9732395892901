@import "former-kit-skin-pagarme/dist/styles/spacing.css";
@import "former-kit-skin-pagarme/dist/styles/typography.css";
@import "former-kit-skin-pagarme/dist/styles/colors/light.css";

.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.userName {
  font-size: 14px;
  color: var(--color-birdperson-gray-600);
  font-weight: bold;
  line-height: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}

.companyInformations {
  max-width: calc(100% - 42px);
}

.userEmail {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: var(--caption-font-size);
  color: var(--color-squanchy-gray-200);
  line-height: 14px;
}

.iconContainer {
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  border: none;
  width: 24px;
  height: 24px;
}

.footerContainer {
  width: 240px;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-tiny);
}

.popoverButton {
  border: none;
  background-color: transparent;
  padding: var(--spacing-tiny);
  text-align: start;
  cursor: pointer;
  border-radius: var(--spacing-tiny);
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  color: var(--color-birdperson-gray-600);
}

.popoverButton:hover {
  background-color: var(--color-squanchy-gray-20);
}

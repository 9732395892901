@import "former-kit-skin-pagarme/dist/styles/spacing.css";
@import "former-kit-skin-pagarme/dist/styles/colors/light.css";
@import "former-kit-skin-pagarme/dist/styles/typography.css";

.flexWrapper {
  gap: var(--spacing-medium);
}

.modalTitle {
  color: var(--color-squanchy-gray-300);
  text-align: center;
  font-weight: 600;
  margin-bottom: 48px;
  padding-left: var(--spacing-large);
  padding-right: var(--spacing-large);
  margin-top: 0;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-medium);
  margin-bottom: var(--spacing-medium);
}

.buttonText {
  text-transform: none;
}

@import "former-kit-skin-pagarme/dist/styles/colors.css";
@import "former-kit-skin-pagarme/dist/styles/typography.css";
@import "former-kit-skin-pagarme/dist/styles/webfonts/poppins/index.css";

.fakeLoader {
  background-color: var(--color-squanchy-gray-20);
  height: 100vh;
  width: 100vw;

  & svg {
    height: 110px;
    width: 110px;
  }

  & h1 {
    color: var(--color-birdperson-gray-600);
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    margin-bottom: 5px;
  }

  & span {
    color: var(--color-squanchy-gray-300);
    font-size: var(--body-font-size);
    margin-top: 10px;
  }

  & span:first-of-type {
    margin-right: 4.5px;
  }
}

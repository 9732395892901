@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.cardWrapper {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: var(--spacing-small, 16px);
}

.itemToggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
}

.itemToggleText {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: var(--spacing-tiny, 8px);
  padding-bottom: var(--spacing-tiny, 8px);
}

@import "former-kit-skin-pagarme/dist/styles/colors.css";

.onboardingProgress {
  align-items: center;
  display: flex;
  flex-direction: column;

  & > .skipOnboarding {
    color: var(--color-barney-purple-100);
    font-size: 14px;
    margin-top: 40px;
  }
}

@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.boxActions {
  margin-top: var(--spacing-small, 16px);
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-tiny, 8px);
}

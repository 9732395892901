@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.cardContainer {
  display: block;
  background-color: var(--theme-color-background-surface, #ffffff);
  border: 1px solid;
  border-color: var(--theme-color-border-low, hsla(210, 94.1%, 11.1%, 0.075));
  border-radius: var(--global-corner-radius-medium, 12px);
  padding: var(--global-space-250, 20px);
}

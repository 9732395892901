@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.cardContainer {
  display: flex;
  flex-direction: column;
}

.cardImage {
  display: flex;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
  margin-bottom: var(--spacing-large, 32px);
}

.cardText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-small, 16px);
  text-align: center;
}

@import "former-kit-skin-pagarme/dist/styles/colors/light.css";
@import "former-kit-skin-pagarme/dist/styles/spacing.css";
@import "former-kit-skin-pagarme/dist/styles/typography.css";

.accountWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #e5e5e5;
  padding: 0 var(--spacing-medium);
}

.accountCard {
  padding: 56px 48px;
  width: 416px;
  max-width: 100%;
  background: var(--color-white);
  box-shadow: 0 2px 4px rgba(var(--color-black), 0.16);
  border-radius: var(--spacing-tiny);
  box-sizing: border-box;
}

.logoWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-medium);
}

.confirmButton {
  display: flex;
  flex-direction: column;
  padding-top: var(--spacing-small);
}

.loaderWrapper {
  position: relative;
  padding-top: var(--spacing-large);
}

.forgotPasswordWrapper {
  display: block;
  padding-top: var(--spacing-medium);

  & > .forgotPassword {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 14px;
  }
}

.changeMail {
  display: block;
  width: 100%;
  text-align: right;
  margin: var(--spacing-tiny) 0 0;
  font-size: 14px;
}

.formContent {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--spacing-small);

  & > div:not(:last-child) {
    padding-bottom: var(--spacing-medium);
  }

  & > div.formWithOption { /* stylelint-disable-line selector-no-qualifying-type */
    padding-bottom: var(--spacing-tiny);
  }
}

.hugeButton {
  flex: 1;
  margin-top: var(--spacing-small);
}

.confirmationButton {
  flex: 1;
  margin-top: var(--spacing-large);
}

.link {
  cursor: pointer;
  color: var(--color-picklerick-green-100);
  font-size: 14px;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    color: var(--color-picklerick-green-200);
    text-decoration: none;
  }
}

.logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
}

.bottomMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--spacing-large);
  font-weight: bold;
  line-height: 1.5;
  color: var(--color-black);
}

.buttonBack {
  margin-top: var(--spacing-small);
}

.changeEnvButton {
  margin-top: 104px;
}

.changeEnvButton:hover {
  text-decoration: none;
}

.confirmationMessage,
.subtitle {
  display: block;
  color: var(--color-squanchy-gray-400);
  text-align: center;
  max-width: 80ch;
  margin-top: var(--spacing-large);
  margin-bottom: var(--spacing-large);
}

.confirmationMessage {
  margin: 0;
}

.comunication {
  margin: var(--spacing-medium) 0;
  max-width: 416px;
  text-align: center;
}

.comunication a {
  font-size: 16px;
}

@keyframes slowUp {

  from {
    transform: translateY(50%);
  }

  to {
    transform: translateX(0);
  }
}

.slowUp {
  animation: slowUp ease-out 0.3s;
}

@keyframes slowUpOpacity {

  from {
    opacity: 0;
    transform: translateY(50%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.slowUpOpactity {
  animation: slowUpOpacity ease-out 0.3s;
}

@import "former-kit-skin-pagarme/dist/styles/spacing.css";
@import "former-kit-skin-pagarme/dist/styles/colors/light.css";

.title {
  font-size: 24px;
  text-align: center;
  margin: 0;
}

.message {
  font-size: 16px;
  text-align: center;
  margin: var(--spacing-small) 0;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: var(--spacing-small);
}

.resendCode {
  color: var(--color-barney-purple-100);
  background-color: transparent;
  border: 0;
  text-decoration: underline;
  font-size: 14px;
  align-self: flex-end;
  cursor: pointer;
  margin: var(--spacing-tiny) 0;
}

.resendCode[disabled] {
  color: var(--color-birdperson-gray-500);
  cursor: not-allowed;
}

.alert {
  margin: 0;
}

.footer {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 80px;
}

.footer.onBack {
  justify-content: flex-end;
}

[class*="index_input"] label {
  top: 0;
  left: 0;
}

[class*="index_input"] input {
  padding-bottom: 0;
  margin-bottom: -1px;
}

/* stylelint-disable declaration-no-important */
@import "former-kit-skin-pagarme/dist/styles/colors/light.css";
@import "former-kit-skin-pagarme/dist/styles/typography.css";

.cardContainerWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
}

.cardInfoWrapper {
  flex: 1;
}

.cardTitle {
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: var(--spacing-tiny);
}

.cardDescription {
  display: block;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.28px;
  margin-top: 0;
  margin-bottom: 0;
}

.cardDescription a {
  color: var(--color-squanchy-gray-400);
  text-decoration: underline;
}

.emptyBox {
  border: 1px solid var(--color-squanchy-gray-20);
}

.buttonDelete button {
  border-color: var(--color-beth-pink-100) !important;
  color: var(--color-beth-pink-100) !important;

  &:hover,
  &:focus,
  &:active {
    background-color: var(--color-beth-pink-100) !important;
    color: #ffffff !important;
    border-color: var(--color-beth-pink-300) !important;
    outline-color: var(--color-beth-pink-300) !important;
    box-shadow: var(--color-beth-pink-300) 0 0 0 2px inset !important;
  }
}

.tootipAddKey {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 8px;
}

.tooltip {
  display: flex;
  align-items: center;
  gap: var(--spacing-tiny, 8px);
}

@import "former-kit-skin-pagarme/dist/styles/colors/light.css";
@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.sidebarIcon {
  fill: var(--color-squanchy-gray-100);
}

.headerContainer {
  position: absolute;
  top: 0;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-squanchy-gray-20);

  & header {
    width: 100%;
  }
}

.footerContainer {
  position: absolute;
  bottom: 0;
  padding: 16px;
  background-color: var(--color-white);
  border-top: 1px solid var(--color-squanchy-gray-20);
}

.linksContainer {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 134px);
  margin-top: 75px;

  & ul > li > ul > li > button {
    font-weight: normal;
  }
}

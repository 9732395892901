@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.stepsContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: var(--spacing-small, 16px);
  margin-bottom: var(--spacing-medium, 24px);
}

.stepsItem {
  display: flex;
  align-items: center;
  gap: var(--spacing-tiny, 8px);
}

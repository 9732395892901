@import "former-kit-skin-pagarme/dist/styles/colors.css";
@import "former-kit-skin-pagarme/dist/styles/radio-group/index.css";

.radioCardOptions {
  display: flex;
  flex-flow: column;
}

.radioCardOptions button {
  padding: 0;
}

.radioContainer {
  margin-top: 24px;
  margin-bottom: 32px;
}

.radioContainer > div {
  margin-top: 16px;
}

.radioContainer > div:first-child {
  margin-top: 0;
}

.card {
  display: flex;
  flex-flow: column;
}

.card > * {
  margin-top: 16px;
  margin-left: 16px;
}

.label {
  padding: 16px;
  margin: 0;
}

.input {
  height: 24px;
  width: 24px;
  margin-right: 16px;
}

.input:before {
  height: 16px;
  width: 16px;
  top: 3px;
  left: 3px;
}

.labelText {
  color: var(--color-barney-purple-100);
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 8px;
}

.descriptionText {
  color: var(--color-birdperson-gray-800);
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}

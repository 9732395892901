@import "former-kit-skin-pagarme/dist/styles/colors.css";
@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.onboardingBackground {
  background-color: var(--color-squanchy-gray-20);
  display: flex;
  min-height: 100vh;
}

.logoAndImage {
  background: var(--color-white);
  box-sizing: border-box;
  color: var(--color-black);
  display: grid;
  flex: 1;
  grid-template-rows: auto 1fr;
  padding: 40px 64px;
}

.centerImage {
  justify-self: center;
  margin: auto 0;
  width: 100%;
}

.children {
  box-sizing: border-box;
  padding: 40px 64px;
  flex: 1;
}

@media (max-width: 768px) {

  .logoAndImage {
    display: none;
  }
}

@media (max-width: 425px) {

  .logoAndImage {
    display: none;
  }

  .children {
    padding: 40px 16px;
  }
}

@media (min-width: 1440px) {

  .centerImage {
    max-width: 850px;
  }

  .children {
    padding-left: 112px;
    padding-right: 112px;
  }
}

@import "former-kit-skin-pagarme/dist/styles/webfonts/poppins/index.css";
@import "former-kit-skin-pagarme/dist/styles/colors.css";
@import "former-kit-skin-pagarme/dist/styles/spacing.css";
@import "former-kit-skin-pagarme/dist/styles/typography.css";

.loading {
  display: flex;
  justify-content: center;
  margin-top: 100px;

  & svg {
    width: 100px;
  }
}

.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  & > span {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}

.onboardingQuestions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.title {
  color: var(--color-birdperson-gray-600);
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 46px;
  margin-bottom: 50px;
}

@media (max-width: 768px) {

  .title {
    font-size: 20px;
    line-height: 32px;
    text-align: center;
  }
}

@media (max-width: 430px) {

  .error {

    & > button {
      padding: 0 var(--spacing-medium);
    }
  }
}

@media (max-width: 320px) {

  .title {
    font-size: var(--body-font-size);
    line-height: 24px;
  }

  .error {

    & > button {
      padding: 0 var(--spacing-tiny);
    }
  }
}

@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.cardWrapper {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: var(--spacing-small, 16px);
  margin-bottom: var(--spacing-small, 16px);
}

.paddingBottom {
  padding-bottom: 20px;
}

.boxActions {
  margin-top: var(--spacing-small, 16px);
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-tiny, 8px);
}

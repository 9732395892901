@import "former-kit-skin-pagarme/dist/styles/webfonts/poppins/index.css";
@import "former-kit-skin-pagarme/dist/styles/colors.css";
@import "former-kit-skin-pagarme/dist/styles/spacing.css";
@import "former-kit-skin-pagarme/dist/styles/typography.css";

.onboardingWelcome {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.welcome {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
}

.welcomeText {
  line-height: 20px;
  margin-top: var(--spacing-small);
}

.welcomeDescription {
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 0 48px;
}

.welcomeTitle {
  color: var(--color-birdperson-gray-600);
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  font-weight: var(--body-text-weight);
  margin-bottom: var(--spacing-tiny);
}

.welcomeDescriptionTitle {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-medium);

  & p {
    margin: 0 0 0 var(--spacing-small);
    font-weight: bold;
  }

  & svg {
    color: var(--color-barney-purple-100);
  }
}

.welcomeContent {
  margin-bottom: 42px;
}

@media (max-width: 425px) {

  .onboardingWelcome {
    align-items: center;
  }

  .onboardingWelcome button {
    padding: 0;
  }

  .welcomeTitle {
    font-size: var(--body-font-size);
    line-height: 24px;
  }

  .welcomeText {
    font-size: 12px;
  }

  .welcomeDescription {
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    text-align: center;
  }

  .welcomeDescriptionTitle {
    flex-direction: column;
    justify-content: center;

    & p {
      margin: var(--spacing-tiny) 0 0 0;
    }
  }
}

@media (max-width: 375px) {

  .welcomeDescriptionTitle {
    font-size: 12px;
  }
}

@media (max-width: 320px) {

  .welcomeTitle {
    font-size: 14px;
  }

  .welcomeText {
    font-size: 10px;
  }

  .welcomeDescription {
    font-size: 10px;
    margin: 0;
    text-align: center;
  }
}

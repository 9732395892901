@import "former-kit-skin-pagarme/dist/styles/colors/light.css";

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.overlay {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  margin: 0;
  padding: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.opaqueBackground {
  background-color: var(--color-squanchy-gray-20);
}

.spinner > div {
  width: 80px;
}

@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.cardWrapper {
  padding-left: 20px;
  padding-right: 20px;
}

.input {
  margin-top: 12px;
  margin-bottom: 20px;
}

.selectItems {
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-tiny, 8px);
}

.buttonWrapper {
  padding: 20px;
}

.buttonWrapper > button {
  width: 100%;
}

@import "former-kit-skin-pagarme/dist/styles/spacing.css";
@import "former-kit-skin-pagarme/dist/styles/typography.css";
@import "former-kit-skin-pagarme/dist/styles/colors/light.css";

.container {
  display: flex;
  width: 100%;
}

.companyName {
  font-size: 14px;
  color: var(--color-birdperson-gray-600);
  font-weight: bold;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.companyCnpj {
  font-size: var(--caption-font-size);
  color: var(--color-squanchy-gray-200);
  line-height: 14px;
}

.companyInformations {
  margin-left: var(--spacing-tiny);
  width: calc(100% - 40px);
}

/* stylelint-disable declaration-no-important */
@import "former-kit-skin-pagarme/dist/styles/colors/light.css";
@import "former-kit-skin-pagarme/dist/styles/typography.css";
@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.alertWrapper {
  margin-bottom: var(--spacing-small);
}

.modalDescription {
  display: block;
  font-size: 14px;
  margin-top: var(--spacing-tiny);
  margin-bottom: var(--spacing-medium);
}

.inputWrapper {
  display: block;
  margin-bottom: var(--spacing-medium);
}

.buttonDelete button:not([disabled]) {
  background-color: var(--color-beth-pink-100) !important;
  border-color: var(--color-beth-pink-100) !important;

  &:hover,
  &:focus,
  &:active {
    background-color: var(--color-beth-pink-200) !important;
    border-color: var(--color-beth-pink-300) !important;
    outline-color: var(--color-beth-pink-300) !important;
    box-shadow: var(--color-beth-pink-300) 0 0 0 2px inset !important;
  }
}

@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.qrCodeContainer {
  display: flex;
  padding-top: 24px;
  gap: var(--spacing-large, 32px);
}

.leftColumn {
  flex: 1;
}

.list {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-tiny, 8px);
}

.listItem {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-top: var(--spacing-tiny, 8px);
  padding-bottom: var(--spacing-tiny, 8px);
}

.qrcodeColumn {
  flex: 0 0 189px;
  position: relative;
  width: 189px;
}

.qrcodeImage {
  width: 189px;
  height: 189px;
}

.expiresWrapper {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-small, 8px);
  gap: var(--spacing-tiny, 8px);
}

.expiresText {
  display: flex;
  align-items: center;
  gap: 4px;
}

.expiredWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

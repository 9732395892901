@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.toast {
  position: fixed;
  top: var(--spacing-small, 16px);
  right: var(--spacing-small, 16px);
  z-index: 1000;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-tiny, 8px);
  margin-top: var(--spacing-small, 16px);
}

.copyWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-tiny, 8px);
  margin-top: var(--spacing-small, 16px);
}

.copyWrapper > .input {
  width: 100%;
  flex: 1;
}

.copyWrapper > .button {
  flex: 0 0 48px;
}

@import "former-kit-skin-pagarme/dist/styles/colors.css";
@import "former-kit-skin-pagarme/dist/styles/spacing.css";
@import "former-kit-skin-pagarme/dist/styles/typography.css";

.image {
  margin-bottom: var(--spacing-medium);
}

.title > * {
  color: var(--color-squanchy-gray-400);
  font-size: var(--headline-font-size);
  margin: 0;
  margin-bottom: var(--spacing-tiny);
}

.message {
  color: var(--color-squanchy-gray-400);
  font-size: var(--body-font-size);
}

.actions {
  display: flex;
}

.actions > * {
  margin-left: var(--spacing-medium);

  &:first-child {
    margin-left: unset;
  }
}

@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.cardContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-small, 16px);
}

.cardText {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-tiny, 8px);
  flex: 1;
}

.cardImage {
  flex: 0 0 84px;
}

@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.dropDownOptions button {
  margin-top: var(--spacing-medium);
  width: 100%;
}

.dropDownOptions > div > div > div > span {
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 425px) {

  .dropDownOptions button {
    padding: 0;
  }
}

@import "former-kit-skin-pagarme/dist/styles/spacing.css";

.permissionsWrapper {
  margin-top: var(--spacing-small, 16px);
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.title {
  margin-bottom: 4px;
}
